.basket-item{
    border: 1px solid #9EA9B6;
}
.basket-item__header{
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: 1px solid #9EA9B6;
    background-color: #fff;
}

.basket-item__header svg{
    margin-left: 30px;
    cursor: pointer;
}

.basket-item__body{
    padding: 40px;
    background-color: #fff;
}
.create-order{
    border: 1px solid #9EA9B6;
    padding: 40px;
    background-color: #fff;
}

.create-order input{
    width: 100%;
    padding: 13px;
    border: 1px solid #9EA9B6;
}

.create-order .ui.button{
    background-color: #4B65BA;
    color: #fff;
}

.basket-item__main{
    border-right: 1px solid #9EA9B6;
    padding-right: 0!important;
}

.checkout-area{
    padding-left: 0!important;
    background-color: #fff;
}

.checkout-area .row{
    padding: 40px;
}

.checkout-area__header{
    padding: 20px;
    border-bottom: 1px solid #9EA9B6;
}

.checkout-area__header h2{
    font-size: 24px!important;
    font-weight: bold!important;
    height: 29px;
}