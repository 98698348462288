.carousel-cell {
    width: 66%;
    height: 200px;
    margin-right: 10px;
    border-radius: 5px;
}

/* cell number */
.carousel-cell:before {
    display: block;
    text-align: center;
    line-height: 200px;
    font-size: 80px;
    color: white;
}

.carousel-card{
    width: 50%;
    padding: 10px;
}

.flickity-prev-next-button.previous {
    left: -55px;
}
.flickity-prev-next-button.next {
    right: -55px;
}
