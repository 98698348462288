.boxes-form .container{
    padding-left: 0;
}

.boxes-form__actions{
    cursor: pointer;
    color: #3855B3;
}
.boxes-form__actions span{
    padding: 0 10px;
}

.boxes-form label{
    margin: auto 0;
}

.boxes-form .licence{
    position: relative;
}
.boxes-form .licence span{
    background-color: #EB5757;
    color: #fff;
    padding: 5px 20px;
    border-radius: 15px;
    position: inherit;
    z-index: 1;
}
.boxes-form .licence:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 90%;
    height: 1px;
    background-color: #EB5757;
}

.container--licence label{
    color: #EB5757!important;
}
.container--licence input{
    border-color: #EB5757!important;
}

.no-border{
    border: none!important;
}

.download-data-login-banner .login-banner .MuiAlert-icon{
    float: left!important;
}
.download-data-login-banner .login-banner .MuiAlert-message{
    display: flex!important;
    justify-content: space-between!important;
}
.download-data-login-banner .login-banner .actions{
    margin: auto 0;
}
.download-data-login-banner .login-banner .MuiAlert-root{
    display: block;
}

.Underline{
    color: #3855B3;
}

.color-danger{
    color: #ee8989
}
.my-border-danger{
    border: 0.5px solid #ee8989;
    border-radius: 5px;
}
.my-border-danger input::placeholder{
    color: #ee8989!important;
}