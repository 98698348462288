.order{
    border: 1px solid #9EA9B6;
    border-bottom: none;
}

.order-header{
    background-color: #EAEEF5;
    border-bottom: 1px solid #9EA9B6;
}

.order-header, .order-item{
    padding: 15px 45px;
    padding-right: 20px;
}

.order-item{
    border-bottom: 1px solid #9EA9B6;
    background-color: #fff;
}

.order-item svg{
    cursor: pointer;
}

.order-item__menu{
    position: absolute;
    top: 0;
    left: 30px;
    padding-left: 0;
    padding: 0 10px;
    background: #fff;
    width: 100%;
    display: none;
    cursor: pointer;
    border: 0.5px solid #333;
}
.order-item__menu li{
    list-style: none;
    padding: 15px 0;
}

.order-item-svg:hover .order-item__menu{
    display: block;
}