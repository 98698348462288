.code-request {
    margin-top: 25px;
    background: #E0E7EC;
    padding: 20px;
    height: 335px;
    border: 1px solid #9EA9B6;
}

.modal-order-details{
    text-decoration: underline!important;
}