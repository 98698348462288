.overview-footer{
    margin-top: 350px;
}

.overview-footer .divider{
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}
.overview-footer .divider span{
    position: inherit;
    z-index: 999;
    padding: 10px;
    background: #fff;
}
.overview-footer .divider:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 8px;
    width: 100%;
    height: 1px;
    background-color: #333;
}