.Checkbox-box{
    display: flex !important;
    flex-direction: column;
}

.Checkbox-item {
    margin-bottom: 10px;
}

.Checkbox-title {
    margin-bottom: 20px;
}