@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TopBar{
    height: 107px;
    left: 0px;
    background: #FFFFFF;
}
.NavTools{
    display: flex; 
    justify-content: flex-end;
    height: 100%;
}
.NavToolsBeetween{
    display: flex; 
    justify-content: space-between;
    height: 100%;
}

.NavToolsItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    margin-right: 60px;
}

.NavToolsItemsLeft{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    margin-right: 60px;
    margin-left: 50px;
}

.NavToolsOrder{
    border-right: 1px solid #9EA9B6;
}

.order-nav-item{
    width: 150px;
    display: flex;
    margin: auto 0;
    color: #333;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.order-nav-item h3{
    margin: auto!important;
    margin-left: 15px!important;
}

.order-nav-item h3{
    margin-top: 0;
}

.itemsInBasket{
    display: flex;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #3855B3;
    color: #fff;
}
.basket-icon{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}


.basket-item{
    border: 1px solid #9EA9B6;
}
.basket-item__header{
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-bottom: 1px solid #9EA9B6;
    background-color: #fff;
}

.basket-item__header svg{
    margin-left: 30px;
    cursor: pointer;
}

.basket-item__body{
    padding: 40px;
    background-color: #fff;
}
.create-order{
    border: 1px solid #9EA9B6;
    padding: 40px;
    background-color: #fff;
}

.create-order input{
    width: 100%;
    padding: 13px;
    border: 1px solid #9EA9B6;
}

.create-order .ui.button{
    background-color: #4B65BA;
    color: #fff;
}

.basket-item__main{
    border-right: 1px solid #9EA9B6;
    padding-right: 0!important;
}

.checkout-area{
    padding-left: 0!important;
    background-color: #fff;
}

.checkout-area .row{
    padding: 40px;
}

.checkout-area__header{
    padding: 20px;
    border-bottom: 1px solid #9EA9B6;
}

.checkout-area__header h2{
    font-size: 24px!important;
    font-weight: bold!important;
    height: 29px;
}
.banner-price{
    background-color: #fff;
    border: 1px solid #9EA9B6;
}

.banner-price__content{
    padding: 40px;
}
.detail-footer{
    background-color: #fff;
    padding: 50px;
}
.detail-footer .card{
    position: relative;
}
.carousel-container{
    max-width: 1250px;
    width: 100%;
    margin: auto;
}
.card {
    background: #FFFFFF;
    border: 1px solid #9EA9B6;
    box-sizing: border-box;
    border-radius: 10px !important;
    padding: 20px;
    margin-bottom: 25px;
}

.carousel-cell {
    width: 66%;
    height: 200px;
    margin-right: 10px;
    border-radius: 5px;
}

/* cell number */
.carousel-cell:before {
    display: block;
    text-align: center;
    line-height: 200px;
    font-size: 80px;
    color: white;
}

.carousel-card{
    width: 50%;
    padding: 10px;
}

.flickity-prev-next-button.previous {
    left: -55px;
}
.flickity-prev-next-button.next {
    right: -55px;
}

.details-header{
    margin-bottom: 25px;
}
.details-header div{
    border-bottom: 1px solid #ddd;
}

.details-header ul{
    display: flex;
    padding-left: 0;
    margin: 0;
}

.details-header li{
    list-style: none;
    padding: 10px 19px;
    font-weight: bold;
    cursor: pointer;
}

.details-header li.active{
    color:  #3855B3;
    border-bottom: 2px solid #3855B3;
}
.overview-footer{
    margin-top: 350px;
}

.overview-footer .divider{
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}
.overview-footer .divider span{
    position: inherit;
    z-index: 999;
    padding: 10px;
    background: #fff;
}
.overview-footer .divider:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 8px;
    width: 100%;
    height: 1px;
    background-color: #333;
}
.card {
    background: #FFFFFF;
    border: 1px solid #9EA9B6;
    box-sizing: border-box;
    border-radius: 10px !important;
    padding: 20px;
    margin-bottom: 25px;
}
.boxes-form .container{
    padding-left: 0;
}

.boxes-form__actions{
    cursor: pointer;
    color: #3855B3;
}
.boxes-form__actions span{
    padding: 0 10px;
}

.boxes-form label{
    margin: auto 0;
}

.boxes-form .licence{
    position: relative;
}
.boxes-form .licence span{
    background-color: #EB5757;
    color: #fff;
    padding: 5px 20px;
    border-radius: 15px;
    position: inherit;
    z-index: 1;
}
.boxes-form .licence:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 90%;
    height: 1px;
    background-color: #EB5757;
}

.container--licence label{
    color: #EB5757!important;
}
.container--licence input{
    border-color: #EB5757!important;
}

.no-border{
    border: none!important;
}

.download-data-login-banner .login-banner .MuiAlert-icon{
    float: left!important;
}
.download-data-login-banner .login-banner .MuiAlert-message{
    display: flex!important;
    justify-content: space-between!important;
}
.download-data-login-banner .login-banner .actions{
    margin: auto 0;
}
.download-data-login-banner .login-banner .MuiAlert-root{
    display: block;
}

.Underline{
    color: #3855B3;
}

.color-danger{
    color: #ee8989
}
.my-border-danger{
    border: 0.5px solid #ee8989;
    border-radius: 5px;
}
.my-border-danger input::-webkit-input-placeholder{
    color: #ee8989!important;
}
.my-border-danger input:-ms-input-placeholder{
    color: #ee8989!important;
}
.my-border-danger input::placeholder{
    color: #ee8989!important;
}
.login-banner .MuiPaper-root{
    border-radius: 10px;
    border: 0.5px solid #ee8989;
}
.code-request {
    margin-top: 25px;
    background: #E0E7EC;
    padding: 20px;
    height: 335px;
    border: 1px solid #9EA9B6;
}

.modal-order-details{
    text-decoration: underline!important;
}
.order{
    border: 1px solid #9EA9B6;
    border-bottom: none;
}

.order-header{
    background-color: #EAEEF5;
    border-bottom: 1px solid #9EA9B6;
}

.order-header, .order-item{
    padding: 15px 45px;
    padding-right: 20px;
}

.order-item{
    border-bottom: 1px solid #9EA9B6;
    background-color: #fff;
}

.order-item svg{
    cursor: pointer;
}

.order-item__menu{
    position: absolute;
    top: 0;
    left: 30px;
    padding-left: 0;
    padding: 0 10px;
    background: #fff;
    width: 100%;
    display: none;
    cursor: pointer;
    border: 0.5px solid #333;
}
.order-item__menu li{
    list-style: none;
    padding: 15px 0;
}

.order-item-svg:hover .order-item__menu{
    display: block;
}
.SearchForm {
    padding: 30px 20px;
}

.SearchFormBox{
    background: #FFFFFF;
    border: 1px solid #9EA9B6;
    border-radius: 4px;
    padding: 20px;
    height: 100%;
}

.SeachForm-CTA{
    display: flex;
    justify-content: space-between;
}

.FormField{
    padding: 0px 0px 30px 0px;
}

.Tooltip{
    width: 20px;
}

.Tooltip-right{
    display: flex;
    align-self: flex-end;
}

.LabelRadio{
    display: flex;
    align-self: center;
}

.SpaceBeetwwen{
    margin-bottom: 35px;
}

.icon-info{
    display: flex;
    flex-direction: column;
}
.icon-info img{
    margin: 0;
}
.Checkbox-box{
    display: flex !important;
    flex-direction: column;
}

.Checkbox-item {
    margin-bottom: 10px;
}

.Checkbox-title {
    margin-bottom: 20px;
}
#root{
    width: 100%;
    height: 100%;
}

.box {
    background: #FFFFFF;
    border: 1px solid #3855B3;
    box-sizing: border-box;
    border-radius: 0px 4px 4px 4px;
}

h1{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 28px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h2{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 18px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h3{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 16px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h4{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: unset !important;
    line-height: unset !important;
    color: rgba(24, 24, 25, 0.7) !important;
}

h2.Title{
    font-weight: 500 !important;
}

h3.Title{
    font-weight: 500 !important;
}

.Red{
    color: #EB5757 !important;
    text-decoration: underline !important;
}

.Underline{
    text-decoration: underline !important;
}

.Page{
    padding: 50px;
}

.SearchFormTabButtonEnabled{
    color: #FFFFFF;
    height: 44px;
    width: 201px;
    background: #455FB3;
    border-radius: 4px 4px 0px 0px;
    margin-right: 10px;
    border: none;
}

.SearchFormTabButtonDisabled{
    color: #FFFFFF;
    height: 44px;
    width: 201px;
    background: #CBD1D7;
    border-radius: 4px 4px 0px 0px;
    margin-right: 10px;
    border: none;
}

.ui.input{
    width: 100% !important;
}

.ui.search .prompt{
    border-radius: .28571429rem !important;
}

.ui.primary.button{
    background: #3855B3 !important;
    border-radius: 4px !important;
}

.ui.secondary.button{
    background: #4F4F4F !important;
    border-radius: 4px !important;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before{
    background: #3855B3 !important;
}
.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
    color: #fff
     !important
}

.ui.segment {
    height: 30%;
    padding: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    background-color: #F8F8F8 !important;
}

.ui.dimmer {
    padding: 0px;
}

.ui.inverted.dimmer {
    background-color: #F8F8F8 !important;
}

.ui.pagination.menu .item{
    color: #3855B3E5
}

.ui.pagination.menu .active.item{
    background: #3855B314 !important;
    color: #3855B3E5 !important;
}

table.ui.celled.table{
    display: table-caption;
}

.Horizontal{
    display: flex;
    flex-direction: row;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.MuiInputBase-input{
    font-size: 16px !important;
}

.MuiOutlinedInput-input{
    padding: 10px 10px !important;
}

.MuiPaper-root{
    margin-bottom: 22px;
}

.MuiPaper-elevation1{
    box-shadow: unset !important;
}

.AccordionSidebar > .MuiAccordion-rounded {
    border: 1px solid #9EA9B6;
    border-radius: 4px !important;

    background: #EAEEF5;
}

.AccordionSidebar > .MuiPaper-root{
    margin-bottom: 17px;
}

.AccordionSidebar > .MuiAccordion-root.Mui-expanded{
    margin-bottom: 17px;
}

.AccordionSidebar > .MuiAccordion-root.Mui-expanded:last-child{
    margin-bottom: 17px;
}

.MuiAccordion-root::before{
    background-color: unset !important;
}

.MuiAccordion-rounded {
    border-radius: 8px !important;
    border: 1px solid #E6E7E9;
}

.TicketSupport{
    background: #E9EAEC;
    box-shadow: 0px 12px 17px 2px rgba(86, 87, 88, 0.1), 0px 5px 22px 4px rgba(86, 87, 88, 0.08), 0px 7px 8px -4px rgba(86, 87, 88, 0.16);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.TicketSupport > h3{
    align-self: center;
    margin: 0px;
}

.TicketSupport > button{
    border: 1px solid #3855B3 !important;
    background-color: unset !important;
    border-radius: 4px !important;
    color: #3855B3 !important;
}

.modal{
    position: fixed !important;
    z-index: 1060 !important;
    display: none !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    outline: 0 !important;
    height: auto !important;;
    top: auto !important;;
    left: auto !important;;
}

.tag-container{
    margin-top:10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.TagLabel{
    background: #EAEEF5 !important;
    border: 1px solid #3855B3 !important;
    border-radius: 4px !important;
    color: #3855B3 !important;
    margin: 5px !important;
}

.delete-icon{
    background-color: #3855B3 !important;
}

.Scroller-Row{
    margin: 5px;
}

.DatasetsAction{
    border-top: 1px solid #9EA9B6;
    margin-top: 40px !important;
    padding-top: 10px;
}

.DatasetsActionItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.IconLabel{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.IconLabelStart{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-self: center;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}



.Pointer{
    cursor: pointer;
}

.ParametersAction{
    border-top: 1px solid #9EA9B6;
    margin-top: 40px !important;
    padding-top: 10px;
}

.ParametersActionItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.ResultsGrid{
    margin-top: 35px !important;
}

.CardCTA{
    margin-top: 20px !important;
}

.PeriodSliderDown{
    margin-top: 35px !important;
}

.ResultsIcon{
    text-align: center;
    width: 30px;
}

.MuiAccordionDetails-root>div{
    width: 100%;
}

.ResultsDividerAccess{
    text-align: center;
    position: relative;
    margin: 10px 0px;
}

.ResultsDividerAccess span{
    position: relative;
    z-index: 2;
    background-color: #FFFFFF;
    padding: 3px 12px;
}

.ResultsDividerAccess:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #9EA9B6;
    top: 50%;
    left: 0px;
    z-index: 1;
}

.disabled{
    color: #9EA9B6;
}

.available{
    color: #27AE60;
}

.unavailable{
    color: #EB5757;
}
.boxes-form{
    padding: 30px 0px;
    border-bottom: 1px solid #ddd;
}

.mandatory{
    color: #EB5757;
    ;
}
.makeStyles-root-23 {
    margin: auto;
    margin-top: 30px;
}
.MuiCollapse-root.MuiCollapse-entered label{
    margin: auto 0;
}
.icon-visualisation span{
    cursor: pointer;
}
.App {
  background: #F8F8F8;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.border-none{
  border: none!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

