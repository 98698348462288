.details-header{
    margin-bottom: 25px;
}
.details-header div{
    border-bottom: 1px solid #ddd;
}

.details-header ul{
    display: flex;
    padding-left: 0;
    margin: 0;
}

.details-header li{
    list-style: none;
    padding: 10px 19px;
    font-weight: bold;
    cursor: pointer;
}

.details-header li.active{
    color:  #3855B3;
    border-bottom: 2px solid #3855B3;
}