.SearchForm {
    padding: 30px 20px;
}

.SearchFormBox{
    background: #FFFFFF;
    border: 1px solid #9EA9B6;
    border-radius: 4px;
    padding: 20px;
    height: 100%;
}

.SeachForm-CTA{
    display: flex;
    justify-content: space-between;
}

.FormField{
    padding: 0px 0px 30px 0px;
}

.Tooltip{
    width: 20px;
}

.Tooltip-right{
    display: flex;
    align-self: flex-end;
}

.LabelRadio{
    display: flex;
    align-self: center;
}

.SpaceBeetwwen{
    margin-bottom: 35px;
}

.icon-info{
    display: flex;
    flex-direction: column;
}
.icon-info img{
    margin: 0;
}