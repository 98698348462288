.NavTools{
    display: flex; 
    justify-content: flex-end;
    height: 100%;
}
.NavToolsBeetween{
    display: flex; 
    justify-content: space-between;
    height: 100%;
}

.NavToolsItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    column-gap: 15px;
    margin-right: 60px;
}

.NavToolsItemsLeft{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 15px;
    margin-right: 60px;
    margin-left: 50px;
}

.NavToolsOrder{
    border-right: 1px solid #9EA9B6;
}

.order-nav-item{
    width: 150px;
    display: flex;
    margin: auto 0;
    color: #333;
    justify-content: space-between;
    width: fit-content;
}
.order-nav-item h3{
    margin: auto!important;
    margin-left: 15px!important;
}

.order-nav-item h3{
    margin-top: 0;
}

.itemsInBasket{
    display: flex;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #3855B3;
    color: #fff;
}
.basket-icon{
    height: fit-content;
    margin: auto;
}

