@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

#root{
    width: 100%;
    height: 100%;
}

.box {
    background: #FFFFFF;
    border: 1px solid #3855B3;
    box-sizing: border-box;
    border-radius: 0px 4px 4px 4px;
}

h1{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 28px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h2{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 18px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h3{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 16px !important;
    font-weight: unset !important;
    line-height: unset !important;
}

h4{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: unset !important;
    line-height: unset !important;
    color: rgba(24, 24, 25, 0.7) !important;
}

h2.Title{
    font-weight: 500 !important;
}

h3.Title{
    font-weight: 500 !important;
}

.Red{
    color: #EB5757 !important;
    text-decoration: underline !important;
}

.Underline{
    text-decoration: underline !important;
}

.Page{
    padding: 50px;
}

.SearchFormTabButtonEnabled{
    color: #FFFFFF;
    height: 44px;
    width: 201px;
    background: #455FB3;
    border-radius: 4px 4px 0px 0px;
    margin-right: 10px;
    border: none;
}

.SearchFormTabButtonDisabled{
    color: #FFFFFF;
    height: 44px;
    width: 201px;
    background: #CBD1D7;
    border-radius: 4px 4px 0px 0px;
    margin-right: 10px;
    border: none;
}

.ui.input{
    width: 100% !important;
}

.ui.search .prompt{
    border-radius: .28571429rem !important;
}

.ui.primary.button{
    background: #3855B3 !important;
    border-radius: 4px !important;
}

.ui.secondary.button{
    background: #4F4F4F !important;
    border-radius: 4px !important;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before{
    background: #3855B3 !important;
}
.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
    color: #fff
     !important
}

.ui.segment {
    height: 30%;
    padding: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    background-color: #F8F8F8 !important;
}

.ui.dimmer {
    padding: 0px;
}

.ui.inverted.dimmer {
    background-color: #F8F8F8 !important;
}

.ui.pagination.menu .item{
    color: #3855B3E5
}

.ui.pagination.menu .active.item{
    background: #3855B314 !important;
    color: #3855B3E5 !important;
}

table.ui.celled.table{
    display: table-caption;
}

.Horizontal{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.MuiInputBase-input{
    font-size: 16px !important;
}

.MuiOutlinedInput-input{
    padding: 10px 10px !important;
}

.MuiPaper-root{
    margin-bottom: 22px;
}

.MuiPaper-elevation1{
    box-shadow: unset !important;
}

.AccordionSidebar > .MuiAccordion-rounded {
    border: 1px solid #9EA9B6;
    border-radius: 4px !important;

    background: #EAEEF5;
}

.AccordionSidebar > .MuiPaper-root{
    margin-bottom: 17px;
}

.AccordionSidebar > .MuiAccordion-root.Mui-expanded{
    margin-bottom: 17px;
}

.AccordionSidebar > .MuiAccordion-root.Mui-expanded:last-child{
    margin-bottom: 17px;
}

.MuiAccordion-root::before{
    background-color: unset !important;
}

.MuiAccordion-rounded {
    border-radius: 8px !important;
    border: 1px solid #E6E7E9;
}

.TicketSupport{
    background: #E9EAEC;
    box-shadow: 0px 12px 17px 2px rgba(86, 87, 88, 0.1), 0px 5px 22px 4px rgba(86, 87, 88, 0.08), 0px 7px 8px -4px rgba(86, 87, 88, 0.16);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.TicketSupport > h3{
    align-self: center;
    margin: 0px;
}

.TicketSupport > button{
    border: 1px solid #3855B3 !important;
    background-color: unset !important;
    border-radius: 4px !important;
    color: #3855B3 !important;
}

.modal{
    position: fixed !important;
    z-index: 1060 !important;
    display: none !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    outline: 0 !important;
    height: auto !important;;
    top: auto !important;;
    left: auto !important;;
}

.tag-container{
    margin-top:10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.TagLabel{
    background: #EAEEF5 !important;
    border: 1px solid #3855B3 !important;
    border-radius: 4px !important;
    color: #3855B3 !important;
    margin: 5px !important;
}

.delete-icon{
    background-color: #3855B3 !important;
}

.Scroller-Row{
    margin: 5px;
}

.DatasetsAction{
    border-top: 1px solid #9EA9B6;
    margin-top: 40px !important;
    padding-top: 10px;
}

.DatasetsActionItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    column-gap: 15px;
}

.IconLabel{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    column-gap: 15px;
}

.IconLabelStart{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    align-self: center;
    column-gap: 15px;
}



.Pointer{
    cursor: pointer;
}

.ParametersAction{
    border-top: 1px solid #9EA9B6;
    margin-top: 40px !important;
    padding-top: 10px;
}

.ParametersActionItems{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    column-gap: 15px;
}

.ResultsGrid{
    margin-top: 35px !important;
}

.CardCTA{
    margin-top: 20px !important;
}

.PeriodSliderDown{
    margin-top: 35px !important;
}

.ResultsIcon{
    text-align: center;
    width: 30px;
}

.MuiAccordionDetails-root>div{
    width: 100%;
}

.ResultsDividerAccess{
    text-align: center;
    position: relative;
    margin: 10px 0px;
}

.ResultsDividerAccess span{
    position: relative;
    z-index: 2;
    background-color: #FFFFFF;
    padding: 3px 12px;
}

.ResultsDividerAccess:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #9EA9B6;
    top: 50%;
    left: 0px;
    z-index: 1;
}

.disabled{
    color: #9EA9B6;
}

.available{
    color: #27AE60;
}

.unavailable{
    color: #EB5757;
}
.boxes-form{
    padding: 30px 0px;
    border-bottom: 1px solid #ddd;
}

.mandatory{
    color: #EB5757;
    ;
}