.detail-footer{
    background-color: #fff;
    padding: 50px;
}
.detail-footer .card{
    position: relative;
}
.carousel-container{
    max-width: 1250px;
    width: 100%;
    margin: auto;
}